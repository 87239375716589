@import "~bootstrap/scss/functions";
@import "src/styles/variables";

.client-card {
  background-color: $white;
  border-radius: 0 3px 3px 0;
  padding: 3.2rem 2.4rem;

  .client-card__logo {
    background-position: center center;
    background-size: cover;
    border: 1px solid $gray-300;
    border-radius: 50%;
    display: inline-block;
    height: 112px;
    margin-bottom: 3 * $spacer;
    width: 112px;
  }

  .client-card__address {
    //height: 256px;
    overflow: hidden;
    position: relative;
    width: 100%;

    .client-card__address-marker {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -100%);
    }
  }
}
