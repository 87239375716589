.btn.icon-btn {
  align-items: center;
  display: inline-flex;
  justify-content: center;

  svg {
    font-size: 1.2rem;
  }

  svg + span,
  span + svg {
    margin-left: 10px;
  }

  &.btn-lg {
    svg {
      font-size: 1.6rem;
    }
  }
}
