@import "~bootstrap/scss/functions";
@import "variables";

.table {
  background-color: $white;
  border: 1px solid $gray-300;
  border-collapse: separate;
  border-radius: 3px;
  border-spacing: 0;

  > thead > * > *,
  > tbody > * > * {
    &:first-child {
      padding-left: 1.6rem;
    }

    &:last-child {
      //padding-right: 6.4rem;
      padding-right: 1.6rem;
    }
  }

  > thead > * > * {
    padding-bottom: 2.4rem;
    padding-top: 2.4rem;
  }

  > tbody > * > * {
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
    vertical-align: middle;
  }

  > tbody tr:last-child > td {
    border-bottom: 0;
  }

  tfoot {
    height: 6.4rem;

    > * > * {
      border-bottom: 0;
    }
  }
}
