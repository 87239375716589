@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.appointment-warning-modal {
  .modal-header {
    border-bottom: 0;
    padding: 2 * $spacer;
  }

  .modal-footer {
    border-top: 0;
    padding: 3 * $spacer 0;

    > * {
      margin: 0 0.5 * $spacer;
    }
  }

  svg {
    font-size: 6.4rem;
  }
}
