@import "~bootstrap/scss/functions";
@import "../../styles/variables";
@import "../../styles/typo";

.circle-badge {
  @extend .table-header;

  background-color: $gray-200;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 3.2rem;
  line-height: 3.2rem;
  text-align: center;
  width: 3.2rem;

  &.circle-badge--large {
    font-size: 3.2rem;
    height: 6.4rem;
    line-height: 6.4rem;
    width: 6.4rem;
  }

  &.circle-badge--xlarge {
    font-size: 4rem;
    height: 12rem;
    line-height: 12rem;
    width: 12rem;
  }

  &.circle-badge--info {
    background-color: $info-shade;
    color: $info;
  }

  &.circle-badge--success {
    background-color: $success-shade;
    color: $success;
  }

  &.circle-badge--danger {
    background-color: $danger-shade;
    color: $danger;
  }
}
