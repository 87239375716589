@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "src/styles/variables";

.footer {
  border-top: 1px solid $gray-300;
  height: 40px;

  .navbar-nav {
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 auto;
    }
  }

  .nav-link {
    margin-right: 1.2rem;
    text-decoration: none;

    @include media-breakpoint-up(sm) {
      margin-right: 4rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
