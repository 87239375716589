@import "~bootstrap/scss/functions";
@import "variables";

.btn {
  text-transform: uppercase;
}

.btn-tertiary {
  color: $white;

  &:hover {
    color: $white;
  }
}
