@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.panel {
  background-color: $white;
  border: 1px solid $gray-300;
  border-radius: 3px;

  .panel-header {
    border-bottom: 1px solid $gray-300;
    padding: 2.4rem 1.6rem;
  }

  .panel-body {
    padding: 3.2rem 1.6rem;
  }

  &.panel--small {
    .panel-body {
      padding: 1.6rem;
    }
  }

  &.panel--large {
    .panel-body {
      padding: 3.2rem 4rem;
    }
  }
}
