@import "~bootstrap/scss/functions";
@import "variables";

.fc {
  background-color: $gray-100;

  .fc-list-day-text {
    text-decoration: none;
  }

  .fc-list-event-time {
    vertical-align: middle;
  }

  .fc-timegrid-now-indicator-arrow {
    border-left-color: $primary;
  }

  .fc-timegrid-now-indicator-line {
    border-color: $primary;
  }

  .fc-list {
    background-color: $gray-100;
  }

  .fc-list-event-dot {
    display: none;
  }

  // slot height
  .fc-timegrid-slot {
    height: 2.2rem;
  }

  .fc-timegrid-col.fc-day-today {
    background-color: transparent;
  }

  .fc-bg-event {
    opacity: 0.6;
  }

  &.fc-theme-standard td,
  &.fc-theme-standard th {
    border-color: #ccc;
  }

  .fc-timegrid-axis,
  .fc-timegrid-slot-label {
    background-color: $white;
    border: 0;
    border-right: 2px solid $gray-300;
  }

  .fc-timegrid-slot-label-cushion {
    padding: 0 10px;
  }

  .fc-col-header-cell {
    background-color: $white;

    a {
      color: $dark;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .fc-col-header-cell-cushion {
    padding: 8px 4px;
  }

  th.fc-day-today {
    a {
      color: $primary;
    }
  }
}
