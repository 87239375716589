@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans/Light/OpenSans-Light.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/Light/OpenSans-Light.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/open-sans/LightItalic/OpenSans-LightItalic.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/LightItalic/OpenSans-LightItalic.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/open-sans/Regular/OpenSans-Regular.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/Regular/OpenSans-Regular.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/open-sans/Italic/OpenSans-Italic.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/Italic/OpenSans-Italic.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans/SemiBold/OpenSans-SemiBold.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/SemiBold/OpenSans-SemiBold.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/open-sans/SemiBoldItalic/OpenSans-SemiBoldItalic.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/SemiBoldItalic/OpenSans-SemiBoldItalic.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/open-sans/Bold/OpenSans-Bold.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/Bold/OpenSans-Bold.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/open-sans/BoldItalic/OpenSans-BoldItalic.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/BoldItalic/OpenSans-BoldItalic.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans/ExtraBold/OpenSans-ExtraBold.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/ExtraBold/OpenSans-ExtraBold.woff?v=1.101") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff2?v=1.101") format("woff2"), url("../fonts/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?v=1.101") format("woff");
}
