@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.dot {
  background-color: $gray-200;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 24px;
  width: 24px;

  &.dot--small {
    height: 12px;
    width: 12px;
  }
}
