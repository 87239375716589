@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "../../styles/variables";

.appointment-reservation-timer {
  align-items: flex-start;
  background-color: $white;
  border: 1px solid $gray-300;
  border-radius: $border-radius-lg;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  padding: 3 * $spacer;

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
    gap: 0;
  }

  svg {
    align-self: center;
    font-size: 4rem;

    @include media-breakpoint-up(lg) {
      margin-right: 3 * $spacer;
    }
  }

  > div {
    @include media-breakpoint-up(lg) {
      margin-right: 3 * $spacer;
    }
  }

  .btn {
    @include media-breakpoint-up(lg) {
      margin-left: auto;
    }
  }
}
