// General
$enable-rfs: false;

// Typo
$font-family-sans-serif: "Open Sans";
$font-size-base: 1.424rem;
$h1-font-size: 2.556rem;
$h2-font-size: 2.281rem;
$h3-font-size: 2.027rem;
$h4-font-size: 1.802rem;
$h5-font-size: 1.602rem;
$font-size-sm: 1.266rem;
$headings-font-weight: 600; //semibold
$headings-line-height: 2.4rem;

// Button
$btn-font-weight: 600;

// Table
$table-th-font-weight: 600;

// Themes
$primary: #c7348b;
$secondary: #243d51;
$tertiary: #949ca6;
$success: #206d2a;
$info: #2584b5;
$warning: #ffa200;
$danger: #bb260a;
$light: #fafafa;
$dark: #212121;
$white: #fff;
$success-shade: #d5eecd;
$info-shade: #def0fa;
$warning-shade: #fbf4d8;
$danger-shade: #fccdc5;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "white": $white
);

// Gray
$gray-50: #fafafa;
$gray-100: #f6f6f6;
$gray-200: #ebebeb;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$grays: (
  "50": $gray-50,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// Theme
$body-bg: $gray-100;
$body-color: $secondary;
$input-bg: $white;

// Configuration
$spacer: 0.8rem;
$spacers: (
  0: 0,
  4: 0.4rem,
  8: 0.8rem,
  10: 1rem,
  16: 1.6rem,
  20: 2rem,
  24: 2.4rem,
  32: 3.2rem,
  40: 4rem,
  105: 10.5rem,
);
$grid-gutter-width: 3.2rem;

// Navbar
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-padding-y: 0.8rem;
$navbar-brand-font-size: 1rem;
$navbar-brand-padding-y: 0;

// Toasts
$toast-font-size: $font-size-base;
$toast-background-color: $white;
$toast-header-background-color: $white;

// Buttons
$input-btn-padding-y: 0.8rem;
$input-btn-padding-x: 1.6rem;
$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 1.6rem;

// Tooltip
$tooltip-max-width: 270px;
$tooltip-border-radius: 3px;
$tooltip-padding-y: $spacer;
$tooltip-padding-x: $spacer;

// Modal
$modal-inner-padding: 2 * $spacer;

// Forms
$form-color-width: 100%;

@import "~bootstrap/scss/variables";
