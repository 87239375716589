@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.appointment-slots {
  align-items: stretch;
  display: flex;
  overflow: hidden;

  .appointment-slots__day {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .appointment-slots__header {
    align-items: center;
    border-bottom: 1px solid $gray-300;
    height: 47px;
    margin-bottom: 8px;
    text-align: center;

    span {
      font-weight: 600;
    }

    .btn {
      color: $secondary;
    }
  }

  .appointment-slots__slots {
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='37' viewBox='0 0 30 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.66961 4.87109V3.48742H4.25742V4.87109H0.66961ZM5.25867 4.87109V3.48742H8.84649V4.87109H5.25867ZM13.9779 7.13781C13.9779 6.75771 14.0752 6.49117 14.2699 6.3382C14.4692 6.1806 14.7103 6.1018 14.993 6.1018C15.2758 6.1018 15.5168 6.1806 15.7162 6.3382C15.9201 6.49117 16.0221 6.75771 16.0221 7.13781C16.0221 7.50865 15.9201 7.7775 15.7162 7.94438C15.5168 8.10661 15.2758 8.18773 14.993 8.18773C14.7103 8.18773 14.4692 8.10661 14.2699 7.94438C14.0752 7.7775 13.9779 7.50865 13.9779 7.13781ZM13.9779 1.18594C13.9779 0.801198 14.0752 0.530026 14.2699 0.372422C14.4692 0.214818 14.7103 0.136016 14.993 0.136016C15.2758 0.136016 15.5168 0.214818 15.7162 0.372422C15.9201 0.530026 16.0221 0.801198 16.0221 1.18594C16.0221 1.55214 15.9201 1.81867 15.7162 1.98555C15.5168 2.14779 15.2758 2.22891 14.993 2.22891C14.7103 2.22891 14.4692 2.14779 14.2699 1.98555C14.0752 1.81867 13.9779 1.55214 13.9779 1.18594ZM21.1535 4.87109V3.48742H24.7413V4.87109H21.1535ZM25.7426 4.87109V3.48742H29.3304V4.87109H25.7426Z' fill='%239E9E9E'/%3E%3C/svg%3E%0A");
    background-position: center top;
    background-repeat: repeat-y;
    display: flex;
    flex-direction: column;
    height: 100%;

    .btn {
      display: block;
      width: 80%;
    }
  }
}

.appointment-slots__more {
  border-top: 1px solid $gray-300;
  display: flex;
  justify-content: center;

  .icon-btn {
    border: 0;
    color: $secondary;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;

    &:hover {
      color: $secondary;
    }
  }
}
