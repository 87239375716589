html {
  font-size: 62.5%;
}

.h1,
h1 {
  line-height: 3.2rem;
}

.table-header,
th {
  font-weight: 600;
  text-transform: uppercase;
}

.navbar-button {
  font-size: 1.424rem;
  font-weight: 600;
  line-height: 1.6rem;
}
