.logo {
  align-items: center;
  display: inline-flex;
  line-height: 25px;
  margin: 0.4rem 0;
}

.logo__image {
  max-height: 25px;

  &.mh-20 {
    max-height: 20px;
  }
}
