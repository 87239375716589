@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.appointment-modal {
  .modal-header {
    padding: 2rem 2.4rem;
  }

  .modal-body {
    padding: 0;

    .appointment-modal__main-row {
      height: 100%;

      > div {
        padding: 3.2rem 2.4rem;
      }
    }
  }

  .appointment-modal__sidebar {
    background-color: $gray-100;
    padding-left: $spacer !important;
    padding-right: $spacer !important;
  }

  .appointment-modal__sidebar-headline {
    display: block;
    margin-bottom: 2 * $spacer;
    padding-left: 3 * $spacer;
    text-transform: uppercase;
  }

  .appointment-modal__sidebar-button {
    background-color: transparent;
    border: 0;
    color: $secondary;
    display: block;
    margin-bottom: $spacer;
    padding: $spacer 3 * $spacer;
    text-align: left;
    width: 100%;

    svg {
      margin-right: $spacer;
    }

    &.appointment-modal__sidebar-button--active {
      background-color: $tertiary;
      color: $white;

      svg {
        color: $primary;
      }
    }
  }
}
