@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.appointment {
  align-items: stretch;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 2px 4px;

  .dot {
    align-self: center;
    flex-shrink: 0;
    margin-right: 0.5 * $spacer;
    margin-top: 0.4rem;
  }

  .appointment__state {
    border-left: 1px solid $gray-200;
    color: $gray-400;
    flex-shrink: 0;
    margin-left: 4px;
    padding-left: 4px;
  }

  .appointment__name {
    flex-grow: 1;
    font-size: $font-size-sm;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fc-list-event {
  .appointment {
    .dot {
      margin-right: $spacer;
    }

    .appointment__name {
      font-size: $font-size-base;
    }
  }
}
