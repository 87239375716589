@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.breadcrumbs {
  color: $gray-500;
  font-size: $font-size-sm;
  margin-bottom: 2 * $spacer;

  a {
    color: $gray-500;
    text-decoration: none;
  }

  > span {
    margin-left: 5px;
  }
}
