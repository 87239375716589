@import "~bootstrap/scss/functions";
@import "variables";

// stylelint-disable-next-line
.rdtPicker td.rdtActive {
  background-color: $primary;

  &:hover {
    background-color: $primary;
  }
}
