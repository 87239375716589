@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.appointment-timer {
  align-items: center;
  background-color: $secondary;
  border-radius: 10px;
  color: $white;
  display: flex;
  padding: 2 * $spacer 3 * $spacer;

  svg {
    font-size: 4rem;
    margin-right: 3 * $spacer;
  }
}
