@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "src/styles/variables";

.navigation {
  background-color: $white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height: 64px;
  left: 0;
  padding: 16px 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;

  @include media-breakpoint-up(md) {
    height: 72px;
  }

  img.logo {
    height: 28px;
    margin: 0;

    @include media-breakpoint-up(md) {
      height: 40px;
    }
  }
}
