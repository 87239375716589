@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.change-mobile-phone-modal {
  .modal-header {
    padding: 2rem 2.4rem;
  }

  .modal-body {
    padding: 1rem 2.4rem 2rem;

    .appointment-modal__main-row {
      height: 100%;

      > div {
        padding: 3.2rem 2.4rem;
      }
    }
  }
}
