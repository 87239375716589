@import "~bootstrap/scss/functions";
@import "variables";

.alert {
  color: $secondary;

  svg {
    margin-right: 11px;
  }

  &.alert-danger {
    svg {
      color: $danger;
    }
  }

  &.alert-success {
    svg {
      color: $success;
    }
  }
}
