@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.appointment-overbook-modal {
  .modal-header {
    border-bottom: 0;
    padding: 2 * $spacer;
  }

  .modal-footer {
    border-top: 0;
    padding: 3 * $spacer 0;

    > * {
      margin: 0 0.5 * $spacer;
    }
  }

  svg {
    font-size: 6.4rem;
  }

  .appointment-overbook-modal__ellipse {
    align-items: center;
    background-color: $warning;
    border-radius: 50%;
    color: $white;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    margin-right: $spacer;
    width: 32px;
  }
}
