@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.multi-select {
  .options {
    display: flex;
    flex-wrap: wrap;
    gap: $spacer;
  }
}
