@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.mobile-phone-input {
  display: flex;
  gap: 32px;

  .mobile-phone-input__select {
    min-width: 109px;
  }

  .mobile-phone-input__control {
    border: 1px solid $gray-400;
    border-radius: $border-radius;
    height: 100%;

    &.mobile-phone-input__control--is-focused {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }

  .mobile-phone-input__single-value {
    height: 100%;
  }

  .mobile-phone-input__indicator {
    padding-left: 0;
  }

  .mobile-phone-input__prefix {
    align-items: center;
    color: $body-color;
    display: flex;
    height: 100%;
    line-height: 18px;

    img {
      margin-left: 8px;
    }
  }
}
