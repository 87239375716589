@import "~bootstrap/scss/functions";
@import "../../../styles/variables";

.calendar {
  .calendar__moving-hint {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px dashed $primary;
    border-radius: 5px;
    bottom: 2rem;
    display: flex;
    left: 50%;
    padding: 2rem;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);
    z-index: 99;

    .btn {
      margin-left: 2rem;
      pointer-events: all;
    }
  }

  .fc {
    .fc-event {
      border: 0;
    }

    .fc-event-main {
      padding: 0;
    }

    .blocking-time {
      opacity: 1;
      width: 20px;

      div {
        height: 100%;
        width: 100%;
      }
    }
  }

  &.calendar--moving,
  &.calendar--copying {
    .fc {
      cursor: pointer;
    }
  }

  .ring {
    animation: ring 5s 0.7s ease-in-out infinite;
    transform-origin: 50% 0;
  }

  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }
}
