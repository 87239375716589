@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.file-input {
  background: $info-shade;
  border: 1px dashed $gray-400;
  border-radius: 3px;
  box-sizing: border-box;
  color: $info;
  cursor: pointer;
  padding: 3 * $spacer;
  text-align: center;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}
