@import "~bootstrap/scss/functions";
@import "variables";

.tooltip {
  &.tooltip-warning {
    .tooltip-arrow:before {
      border-top-color: $warning;
    }

    .tooltip-inner {
      background: $warning;
      color: $white;
    }
  }
}
