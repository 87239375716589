@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "src/styles/variables";

.portal-navigation {
  .navbar-brand {
    display: flex;
    overflow: hidden;
  }

  .navigation__client-name {
    align-items: center;
    border-left: 1px solid $white;
    display: inline-flex;
    flex: 1;
    font-size: $font-size-base;
    margin-left: 16px;
    padding-left: 16px;
  }

  svg {
    font-size: 2rem;
    margin-right: 0.8rem;
  }

  .nav-link {
    align-items: center;
    display: inline-flex;
    font-weight: 600;
    padding: 16px !important;
    position: relative;
    text-decoration: none;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      padding: 6px 16px !important;
    }

    &:hover,
    &.active {
      svg {
        color: $primary;
      }
    }
  }

  .navbar-nav > .nav-link {
    &:hover,
    &.active {
      @include media-breakpoint-up(md) {
        &:not(.dropdown-toggle):after {
          border-bottom: 4px solid $primary;
          bottom: -8px;
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  .dropdown-toggle .nav-link,
  .nav-item:not(.dropdown) {
    align-items: center;
    display: inline-flex;
  }

  .dropdown-menu .nav-link,
  .dropdown-item {
    align-items: center;
    color: $secondary;
    display: inline-flex;
    padding: 10px 11px !important;

    &:hover {
      background-color: $gray-200;
    }

    svg {
      color: $secondary;
    }

    &.active,
    &:active {
      background-color: $gray-200;
    }
  }

  .dropdown-toggle:after {
    margin-left: 0.8rem;
  }
}

.navbar-dark {
  .navbar-nav {
    position: relative;

    .dropdown-menu.show > .nav-link {
      color: $secondary;
      display: flex;
      font-weight: normal;
    }

    &:before {
      background: linear-gradient(to right, transparent, $secondary);
      content: "";
      display: block;
      height: 100%;
      left: -90px;
      position: absolute;
      width: 80px;
      z-index: 100;
    }
  }
}
