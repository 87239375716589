@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "src/styles/variables";

html,
body,
#root {
  height: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 64px;

  @include media-breakpoint-up(md) {
    padding-top: 112px;
  }

  .main {
    margin-bottom: 6.4rem;
  }
}
